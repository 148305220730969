export const nameMap = {
  verify_appreciate: '讚賞保護',
  payout: '提現',
  add_credit: '充值',
  payment: '支付',
  fingerprint: '指紋',
  tag_adoption: '標籤認領',
  circle_management: '圍爐管理',
  circle_interact: '圍爐交互',
  spam_detection: '垃圾文過濾',
  article_channel: '文章頻道分類',
}

export const explanationMap = {
  verify_appreciate:
    '通過ReCaptcha限制讚賞只能在網站上手動進行，不能通過腳本自動觸發。',
  payout: '用戶可以在滿足條件時從法幣錢包裡提現。',
  add_credit: '用戶可以向法幣錢包中充值。',
  payment: '用戶可以向另一個用戶支付法幣，目前僅有文章的「支持作者」按鈕。',
  fingerprint: '透過已紀錄指紋，不發送驗證碼給被封禁用戶。',
  tag_adoption: '用戶可以認領無人主理的標籤',
  circle_management: '用戶可以使用圍爐搭建、管理功能',
  circle_interact: '用戶可以於圍爐追蹤、訂閱、發布眾聊等',
  spam_detection:
    '垃圾文將不會出現在各文章列表中。文章垃圾文概率大於右邊值將自動判定為垃圾文， 可通過標記幹預文章是否為垃圾文。',
  article_channel: '概率大於右邊值時文章將被視為屬於該頻道',
}
