import { graphql, MutateProps } from 'react-apollo'
import gql from 'graphql-tag'

const SET_ARTICLE_CHANNELS = gql`
  mutation SetArticleChannels($input: SetArticleChannelsInput!) {
    setArticleChannels(input: $input) {
      id
      oss {
        channels {
          channel {
            id
            name
          }
          enabled
          score
        }
      }
    }
  }
`

type Response = {
  setArticleChannels: {
    id: string
    oss: {
      channels: Array<{
        channel: {
          id: string
          name: string
        }
        enabled: boolean
        score: number
      }>
    }
  }
}

type InputProps = {
  articleId: string
  channelId: string // channel to be removed or added
  enabledChannels: string[] // all enabled channels
  // all channels
  channels: Array<{
    id: string
    name: string
  }>
}

type Variables = {
  input: {
    id: string
    channels: string[]
  }
}

export type ChildProps = InputProps & MutateProps<Response, Variables>

const withSetArticleChannels = graphql<
  InputProps,
  Response,
  Variables,
  ChildProps
>(SET_ARTICLE_CHANNELS)

export default withSetArticleChannels
