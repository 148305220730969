import * as React from 'react'
import { message, Select } from 'antd'

import ErrorMessage from '../../ErrorMessage'
import withSetArticleChannel, { ChildProps } from './withSetArticleChannel'

type AddArticleChannelState = {
  loading: boolean
  error: any
}

class AddArticleChannelButton extends React.Component<
  ChildProps,
  AddArticleChannelState
> {
  state: Readonly<AddArticleChannelState> = {
    loading: false,
    error: null,
  }

  private _onSelectChannel = async (channelId: string) => {
    this.setState({ loading: true, error: null })

    const { mutate, articleId, enabledChannels } = this.props

    try {
      await mutate({
        variables: {
          input: {
            id: articleId,
            channels: [...enabledChannels, channelId],
          },
        },
      })
      this.setState({ loading: false, error: null })
      message.success('新增頻道成功')
      window.location.reload()
    } catch (error) {
      this.setState({ loading: false, error })
      message.error('新增頻道失敗')
      console.error(error)
    }
  }

  public render() {
    const { channels } = this.props
    const { loading, error } = this.state

    if (error) {
      return <ErrorMessage error={error} />
    }

    return (
      <Select
        placeholder="新增頻道"
        onSelect={this._onSelectChannel}
        style={{ width: 200 }}
        loading={loading}
        disabled={loading}
      >
        {channels.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    )
  }
}

export default withSetArticleChannel(AddArticleChannelButton)
