import * as React from 'react'
import { Button, message } from 'antd'

import withClassifyArticleChannels, {
  ChildProps,
} from './withClassifyArticleChannels'

type ClassifyArticleChannelsState = {
  loading: boolean
  error: any
}

class ClassifyArticleChannelsButton extends React.Component<
  ChildProps,
  ClassifyArticleChannelsState
> {
  state: Readonly<ClassifyArticleChannelsState> = {
    loading: false,
    error: null,
  }

  private _onClassifyChannels = async () => {
    const { mutate, articleId } = this.props

    this.setState({ loading: true, error: null })

    try {
      await mutate({
        variables: {
          input: {
            ids: [articleId],
          },
        },
      })
      this.setState({ loading: false, error: null })
      message.success('頻道分類中')
    } catch (error) {
      this.setState({ loading: false, error })
      message.error('頻道分類失敗')
      console.error(error)
    }
  }

  public render() {
    const { loading } = this.state

    return (
      <Button
        type="primary"
        size="small"
        onClick={this._onClassifyChannels}
        loading={loading}
        disabled={loading}
      >
        觸發分類
      </Button>
    )
  }
}

export default withClassifyArticleChannels(ClassifyArticleChannelsButton)
