import { graphql, MutateProps } from 'react-apollo'
import gql from 'graphql-tag'

const CLASSIFY_ARTICLE_CHANNELS = gql`
  mutation ClassifyArticlesChannels($input: ClassifyArticlesChannelsInput!) {
    classifyArticlesChannels(input: $input)
  }
`

type Response = {
  classifyArticlesChannels: boolean
}

type InputProps = {
  articleId: string
}

type Variables = {
  input: {
    ids: string[]
  }
}

export type ChildProps = InputProps & MutateProps<Response, Variables>

const withClassifyArticleChannels = graphql<
  InputProps,
  Response,
  Variables,
  ChildProps
>(CLASSIFY_ARTICLE_CHANNELS)

export default withClassifyArticleChannels
