import * as React from 'react'
import { Button, Modal, message, Tag } from 'antd'

import withSetArticleChannel, { ChildProps } from './withSetArticleChannel'

type RemoveArticleChannelState = {
  loading: boolean
  error: any
}

class RemoveArticleChannelButton extends React.Component<
  ChildProps,
  RemoveArticleChannelState
> {
  state: Readonly<RemoveArticleChannelState> = {
    loading: false,
    error: null,
  }

  private _onRemoveChannel = () => {
    const {
      mutate,
      articleId,
      channelId,
      enabledChannels,
      channels,
    } = this.props

    const channel = channels.find((c) => c.id === channelId)
    if (!channel) return

    Modal.confirm({
      title: '確認移除頻道？',
      content: (
        <div style={{ marginTop: 16 }}>
          <Tag style={{ marginBottom: 8 }}>{channel.name}</Tag>
        </div>
      ),
      cancelText: '取消',
      okText: '確認移除',
      okType: 'danger',
      onOk: async () => {
        this.setState({ loading: true, error: null })

        try {
          await mutate({
            variables: {
              input: {
                id: articleId,
                channels: enabledChannels.filter((id) => id !== channelId),
              },
            },
          })
          this.setState({ loading: false, error: null })
          message.success('移除頻道成功')
        } catch (error) {
          this.setState({ loading: false, error })
          message.error('移除頻道失敗')
          console.error(error)
        }
      },
    })
  }

  public render() {
    const { loading } = this.state

    return (
      <Button
        type="danger"
        size="small"
        onClick={this._onRemoveChannel}
        loading={loading}
        disabled={loading}
      >
        移除
      </Button>
    )
  }
}

export default withSetArticleChannel(RemoveArticleChannelButton)
